import video from "./assets/video/bg.mp4";
import tg from "./assets/img/tg.png";
import tw from "./assets/img/tw.png";
import ray from "./assets/img/raydium.png";
import bird from "./assets/img/birdeye.png";
import logo from "./assets/img/logo.png";
import banner from "./assets/img/banner.png";
import "./App.css";
import { useRef } from "react";
import { useState } from "react";

function App() {
  const videoRef = useRef(null);
  const [isShow, setIsShow] = useState(true);
  const [text, setText] = useState("2");

  const onPlay = () => {
    setIsShow(false);
    videoRef.current.play();
    videoRef.current.muted = false;
  };

  const onWrong = () => {
    setText("Try Again");
  };

  return (
    <div className="App">
      <video
        ref={videoRef}
        playsInline
        autoPlay
        loop
        muted
        className="App-video"
      >
        <source src={video} type="video/mp4" />
      </video>
      <header className="header">
        <div className="header-logo">
          <img src={logo} alt="" />
        </div>
      </header>
      <div className="App-content">
        <div className="row-btn">
          <div className="row">
            <a
              className="btn"
              href="https://t.me/MortyOnSol_TG"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={tg} alt="" />
            </a>
            <a
              className="btn"
              href="https://twitter.com/MortyOnSol_X"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={tw} alt="" />
            </a>
          </div>
          <div className="row row-2">
            <a
              className="btn"
              href="#"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={ray} alt="" />
            </a>
            <a
              className="btn"
              href="#"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={bird} alt="" />
            </a>
          </div>
        </div>
        {/* <div className="row-ca">
          <span>0x0000000000000</span>
          <span className="email">contact@.....</span>
        </div> */}
        {/* <div className="row-center">
          <a
            className="social"
            href="https://t.me/domdomyesyeseth"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={tg} alt="tg" />
          </a>
          <a
            className="social"
            href="https://twitter.com/domdomyesyeseth"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={tw} alt="tw" />
          </a>
        </div> */}
      </div>
      {/* <div className={`modal-wrapper ${isShow ? "" : "hidden"}`}>
        <div className={`modal`}>
          <h2>Proof that you are not a bot:</h2>
          <span>1 + 1 =</span>
          <div className="modal-btn">
            <button onClick={onWrong}>{text}</button>
            <button onClick={onPlay}>3</button>
          </div>
        </div>
      </div> */}
      <footer className="banner">
        <img src={banner} alt="" />
      </footer>
      <footer className="footer">
        <span>mortyonsol - All rights reserved</span>
      </footer>
    </div>
  );
}

export default App;
